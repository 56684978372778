<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";

/**
 * Instructions Categories Component
 */
export default {

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      categories: [],
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('instructions.title'),
          active: true
        }
      ]
    },

    async loadInstructionCategories() {
      const { data } = await axios.get(`/instruction-category`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          languageId: this.$store.getters["translation/getCurrentLocale"]
        }
      });

      this.categories = data
    }

  },

  mounted() {
    this.$root.$on("change-language", () => {
      this.loadInstructionCategories()
    });
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  },

  async created() {
    try {
      await this.loadInstructionCategories()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('instructions.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <template v-if="categories">
          <template v-if="categories.length !== 0">
            <div class="container">
              <div class="row">
                <div v-for="(category, index) in categories" :key="index" class="col-12 col-lg-4">
                  <a class="clickable-element" :href="`/dashboard/instructions/${category.id}`">
                    <b-card :img-src="category.defaultImage" img-alt="Image" img-top>
                      <b-card-title class="text-center my-2"><h3>{{ category.title }}</h3></b-card-title>
                    </b-card>
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <h5 class="text-center py-3">{{ $t('message.no-elements') }}</h5>
          </template>
        </template>
        <template v-else>
          <div class="text-center py-3">
            <h5>{{ $t('message.loading') }}</h5>
            <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
          </div>
        </template>
      </div>
    </div>

  </Layout>
</template>